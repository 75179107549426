.text-dark {
    color: theme-color("dark");
}
.lightGreenBackground {
  background-color: #ADF6A7;
}
.lightRedBackground {
  background-color: #FF7F7F;
}

.page_background {
    background-color: #F0F7FD !important; 
}

.page_wrapper {
    padding: 30px;
    background-color: #F0F7FD;
}
.page_container {
    background-color: #fff;
    box-shadow: 0px 0px 5px #ddd;
}

/* TABLES */

.table .thead-dark th {
    color: #fff;
    background-color: theme-color("primary");
}
table {
    border:none;
    border-collapse: collapse;
}

table.vertical_borders td {
    border-left: 1px solid $gray-200;
    border-right: 1px solid $gray-200;
}

table td:first-child {
    border-left: none;
}

table td:last-child {
    border-right: none;
}

.table_auto .subtitle {
  background-color: $table-auto-subtitle-color !important;
  color: #000;
  font-weight: bold;
}
.table_auto .subtitle td {
  border-color: $table-auto-subtitle-color;
  border-width: 1px;
  border-style: solid;
}

/* TABS */

.nav-tabs {
    border-bottom: 0px solid #dee2e6;
}
.nav-tabs > li {
    background-color: #fefefe;
    border-radius: 4px 4px 0 0;
    // box-shadow: 0px 0px 5px #ddd;
    font-weight: 900;
    padding-left: 32px;
    padding-right: 32px;
}

/* BUTTONS */

.btn-bold {
    font-weight: 900;
}

/* INPUTS */

.form-control {
    font-size: 0.96rem;
    padding: 0.425rem 0.45rem;
}
.inputFileHidden {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    zIndex: -1;
    width: 30;
}

/* HELPERS */
.cursor_pointer {
    cursor: pointer;
}
.w-32 {
    width: 32px;
}
.h-32 {
    height: 32px;
}
.border-left-3 {
    border-left-width:3px !important;
}
.s-5 {
  font-size: 200%;
}

/* PAD */
.pad-warning {
    background-color: theme-color("warning");
}
.pad-danger {
    background-color: theme-color("danger");
}
.pad-success {
    background-color: theme-color("success");
}


/* SIDEBAR */
.nav-item.active {
    background-color: theme-color("primary");
    color: theme-color("secondary");
}


.nav-item.active .nav-link {
    color: theme-color("secondary");

}

.nav-item.active i {
    color: theme-color("secondary") !important;
}


//chart
.success {
    color: theme-color("success");
}

//custom
.underline-text {
    text-decoration: underline; 
    color: theme-color("blue");
    font-weight: 500;
    cursor: pointer;
  }

  .btn-secondary:hover {
    background-color: #d4ad36;
    
  }

  .yeallow-bg-table-tr .table tbody tr:hover{
    background-color: theme-color("secondary");
}

select {
    cursor: pointer;
}

.profileImg .rounded-circle {
    height: 200px;
    width: 200px;
    object-fit: cover;

}


  .custom-checkbox .custom-control-input:checked .custom-control-label:after{
    color: #0ce22c !important;
    font-style: normal;
    background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 200'%3E%3Cpath d='M10 10h123v123H10z'/%3E%3C/svg%3E" );
  } 

 .myuser .input-group {
    margin-bottom: 15px;
  }

  .margin-top-say {
    margin-top: -10px;

  }

  .nav-tabs > li {
    background-color: #fefefe;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -1px;
}

.nav-tabs > li >a {
    padding: 0 35px;
}

.success-placeholder .form-control::-webkit-input-placeholder {
    color: theme-color("success");
  }

.danger-placeholder .form-control::-webkit-input-placeholder {
    color: theme-color("danger");
  }



.custom-border-color  {
    border-top: 1px solid;
    border-color: theme-color("primary");
    padding-top: 5px;  /* Optional for spacing */
    margin-top: 10px;   /* Optional for spacing */
    // padding-bottom: 8px;   /* Optional for spacing */
  }

  .custom-border-strong {
    border-width: 2px !important; 
  }


  @media only screen and (max-width: 900px) {
    .btn {
        margin-bottom: 10px;
    }

    .input-group, .react-datepicker-wrapper {
        margin-bottom: 15px;
    }

    .btn {
        margin-left: 0px;
    }
  }

  @media (max-width: 996px){
.modal-dialog {
    max-width: 95%;
  
}
}
 
@media (min-width: 992px) {
    .h-lg-100 {
        height: 100% !important;
    }
    .modal-xxl {
        max-width: 900px; } 
}

@media (min-width: 1200px) {
    .modal-xxl {
        max-width: 1190px; } 
}

@media (min-width: 1400px) {
    .modal-xxl {
        max-width: 1390px; } 
}

@media (min-width: 1600px) {
    .modal-xxl {
        max-width: 1590px; } 
}

@media (min-width: 1700px) {
    .modal-xxl {
        max-width: 1690px; } 
}

@media (min-width: 1800px) {
    .modal-xxl {
        max-width: 1790px; } 
}

