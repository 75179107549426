.spinner-height-15 {
  height: 15px;
  width: 15px;
  border: 0.17em solid currentColor;
  border-right-color: transparent;
}

.spinner-height-18 {
  height: 18px;
  width: 18px;
  border: 0.17em solid currentColor;
  border-right-color: transparent;
}