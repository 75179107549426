/* iframe {
    min-height: 400px;
} */

.hide_div .tv-ticker-item-last__header {
    display: none !important;
}
.hide_div .hide_this {
    display: none; /* or visibility: hidden; */
}

.hide_div .tv-embed-widget__body {
    height: 50px !important;
}

.tv-ticker-item-last__header {
    display: none !important;
}

/* if elemen have class hide_div hide all of its childs where calss is hide_this*/
