.pad {
    position: relative;
    border-color: #fff;
    border-width: 0px;
    border-style: solid;
    border-radius: 15px;
    padding: 4px;
    padding-left: 6px;
    padding-right: 0px;
    margin-bottom: 2px;
    color: #fff;
    font-weight: bold;
}
.pad-sm {
    font-size: 100%;
}
.pad-md {
    font-size: 120%;
}
.pad-lg {
    font-size: 140%;
}
.pad_content {
  padding-right: 16px;
}