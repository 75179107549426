
  .container-custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .step {
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 0px solid #adaaaa;
    border-radius: 50%;
    background-color: #adaaaa; /* Unfinished steps color (gray) */
    color: #fff;
    font-weight: bold;
  }

  .step.completed {
    background-color: #FDCD3F; /* Completed step color (green) */
    color: #fff;
    border: 0px solid #FDCD3F;
    transition: background-color 0.3s;

  }

  .line {
    height: 1px;
    background-color: #adaaaa;
    flex: 1;
    }

.line.completed {
    background-color: #FDCD3F; /* Completed line color (green) */

    transition: background-color 0.3s;
    }

  .custom-bg-gray{
    background-color: #adaaaa; /* Unfinished steps color (gray) */
  }
  

  

  .next-button {
    padding: 8px 16px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .next-button:hover {
    background-color: #555;
  }
  







    /* .step-progress-bar {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between; 
  }
  
  .step-container {
    display: flex;
    align-items: center;
    flex-direction: row; 
  }
  
  .step {
    /* display: flex;
    align-items: center;
    justify-content: center; 
    width: 30px;
    height: 30px;
    border: 2px solid #adaaaa;
    border-radius: 50%;
    background-color: #adaaaa; /* Unfinished steps color (gray) 
    color: #fff;
    font-weight: bold;
  }


  
  .step.active {
    background-color: #28a745; /* Active step color (green) 
    color: #fff;
    border: 2px solid #28a745
  }
  
  .step.completed {
    background-color: #28a745; /* Completed step color (green) 
    color: #fff;
    border: 2px solid #28a745 

  }
  
  .line-container {
    display: flex;
    flex: 1; /* Make the line container take up the available space 
  }
  */