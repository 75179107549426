.sidebarRight {
    position: fixed;
    top: 0;
    right: -330px; /* Initially off-screen to the left */
    height: 100%;
    width: 330px;
    background-color: #fff; /* Add your preferred background color */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add shadow for a visual effect */
    transition: left 0.3s ease; /* Add smooth transition effect */
    z-index: 100000;
    overflow: hidden; /* Hide overflow by default */
    overflow-x: hidden; /* Hide horizontal overflow */
    overflow-y: auto; /* Enable vertical scrolling if needed */
  }
  
  .sidebarRight.open {
    right: 0; /* Move to the left to open */
    overflow: hidden; /* Hide overflow when sidebar is open */
    overflow: auto; /* Enable scrolling when sidebar is open */
  }


  .example {

    overflow-y: scroll; /* Add the ability to scroll */
  }

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .example {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }