.icon_button_sm,
.icon_button_normal {
    font-size: 100%;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    padding: 0rem;
}
.icon_button_lg {
    font-size: 120%;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 0rem;
}
.icon_button_xl {
    font-size: 141%;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 0rem;
    /*
    padding: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    */
}