/* Add this CSS to your stylesheet */

/* Add this CSS to your stylesheet */

/* Flex container for the app */
/* .app-container {
    display: flex;
    height: 100vh;
  }
   */
  .sidebar-open32 {
    /* width: 250px; Adjust based on your preference */
    transition: width 0.3s;
  }
  
  .sidebar-closed123 {
    width: 50px !Important; /* Adjust based on your preference */
    height: 69px;
    transition: width 0.0s !important;
  }
  
  .main-content{
    flex: 1;
    margin-left: 250px; /* Default margin for open sidebar */
    transition: margin-left 0.3s;
  }
  
  .main-content-closed {
    margin-left: 0px !Important; /* Same as sidebar-closed width */
    transition: margin-left 0.3s !important;

  }
  
