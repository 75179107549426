.custom-xs-button {
    padding: 0.1rem 0.3rem 0.0rem 0.3rem !important;/* Adjust the padding as needed to make it slightly smaller */
    font-size: 0.6rem !important; /* Adjust the font size as needed to make it slightly smaller */

    /* Add any other styles you want for your custom "xs" size */
  }

  .custom-xs-button-input {
    padding:  0.1rem 0.3rem 0.0rem 0.3rem !important;/* Adjust the padding as needed to make it slightly smaller */
    font-size: 0.9rem !important; /* Adjust the font size as needed to make it slightly smaller */
    box-shadow: none !important;

    /* Add any other styles you want for your custom "xs" size */
  }