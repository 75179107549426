.toggle_container {
    display: inline-block;
    vertical-align: middle;
}
.custom-toggle-slider {
    width: 64px;
}
.custom-toggle-slider:before {
    
}
.toggle_container input:checked + .custom-toggle-slider:before {
    left: 12px !important;
}
.custom-toggle-slider:after {
    left: 12px;
    right: auto;
}
.toggle_container input:checked + .custom-toggle-slider:after {
    right: 18px !important;
}
.toggle_container.toggle_unchecked {
    
}