/* Define the keyframes for the zoom animation */
@keyframes zoomIn {
    from {
      transform: scale(0); /* Start with no scale */
    }
    to {
      transform: scale(1); /* Scale to normal size */
    }
  }
  
  /* Apply the zoom animation to the element */
  .zoomIn {
    animation: zoomIn 0.5s ease forwards; /* Use the defined animation with specified duration and easing */
  }
  
.sk_portfolio_ai_sector_select {
  width: 200px;
}