.padding-0 {
    padding: 0 !important;
}

.fade {
    transition: opacity 0ms !important;
}

.modal.fade .modal-dialog 
{
    -moz-transition: none !important;
    -o-transition: none !important;
    -webkit-transition: none !important;
    transition: none !important;

    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
}