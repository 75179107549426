.sk_desktop_menu {
    .sk_nav_collpase_children {
        .sk_navitem {
            padding-left: 42px;
        }
    }
}
.sk_mobile_menu .sk_navitem {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
}
.sk_mobile_menu .sk_navitem_collapse {
    width: 100%;
}
.sk_mobile_menu .sk_navitem_collapse:hover .nav-link-text {
    background-color: theme-color("primary");
    color: theme-color("secondary");
}
.sk_mobile_menu {
    .sidenav-normal:hover, 
    .sk_navitem:hover, 
    .sk_navitem:hover a, 
    .sk_navitem:hover .sk_icon, 
    .sk_navitem:hover .nav-link-text {
        background-color: theme-color("primary");
        color: theme-color("secondary");
    }
}

.sk_mobile_menu .sk_icon {
    margin-right: 8px;
}
.sk_mobile_menu_parent {
    z-index: 100;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}
.sk_mobile_menu_parent li {
  list-style: none; /* Remove bullets */
}
.sk_mobile_logo img {
    width: 192px;
}
.sk_navitem_collapse_navlink {
    width: 100%;
}
.navbar-vertical .navbar-nav .nav-link.sk_navitem_collapse_navlink {
    display: inline-block;
}
.sk_collapse_icon {
    margin-right: 16px;
}
