.custom_bootstrap_input {
  min-width: 48px;
}
.form-control {
  height: 39px !important;
}

.input-group-text-custom {
  background-color: #e9ecef;
}

.border-yellow {
  border: 1px solid #ffc107 !important;
  /* border-color: #ffc107 !important; */
}

.calculated {
  border-color: #ffc107 !important;

}