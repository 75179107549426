.select_multi_select {
    cursor: pointer;
    min-width: 192px;
}

.select_multi_select_large {
    min-width: 256px;
    cursor: pointer;

}

