.table .thead-dark th a.table_header_link {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
  }
  .limit_column_size {
      word-wrap: break-word;
      max-width: 150px !important;
      text-overflow: ellipsis;
      overflow: hidden;
  }
  .table_header_link_sortable {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .hide_text {
    color: transparent !important;
    
  
  }